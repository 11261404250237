import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./userSlice";
import constReducer from "./constSlice";

const rootReducer = combineReducers({
    user: userReducer,
    const: constReducer,
});

export default rootReducer;