import { Layout } from "antd"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

import Header from "./header"
import Footer from "./footer"

import { useUser } from "../../hooks/user"

const {
    Content
} = Layout

export default function ProtectedLayout({children}){
    const user = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if(!user?._id) navigate("/login", {
            replace: true
        });
    }, [user, navigate])

    return (
        <Layout
            className="min-h-screen h-auto w-screen flex flex-col items-center justify-between"
        >
            <Header />
            <Content
                className="flex flex-col items-center justify-center container grow py-4"
            >
                {children}
            </Content>
            <Footer />
        </Layout>
    )
}