import { useDispatch } from "react-redux";
import { auctionUserLogout } from "../redux/auctionUserSlice";


export default function useAuctionFetch() {
	const dispatch = useDispatch();
    async function _fetch(url, obj = {}, redirect = true) {
		try {
			const baseURL = process.env.REACT_APP_AUCTION_API_URL;
			const callURL = `${baseURL}/${url}`;
			let headers = {};
			let { method = 'GET', body = undefined } = obj;

			if (body) {
				headers['Content-Type'] = 'application/json';
			}

			const options = {
				headers,
				body: JSON.stringify(body),
				method,
				credentials: 'include',
			};

			let res = await fetch(callURL, options);
			res = await res.json();
			if (redirect){
				if (res.status === 401 || res.status === 403) {
					dispatch(auctionUserLogout());
					window.location.href = '/login';
				}
			}
			return res;
		} catch (err) {
			console.error(err);
			return { success: false, message: 'Something went wrong' };
		}
	}
	return _fetch;
}