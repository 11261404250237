import { Button, Form, Input, Modal, Select, Table, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/fetch";
import { useConst } from "../../hooks/const";
import moment from "moment";

export default function Enquiries() {
    const _fetch = useFetch();
    const consts = useConst();
    const [state, setState] = useState("loading"); // ["success", "loading", "error"]
    const [enquiries, setEnquiries] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedEnquiry, setSelectedEnquiry] = useState({});
    const [addEnquiryModalVisible, setAddEnquiryModalVisible] = useState(false);

    async function init(){
        try {
            const enquiries = await _fetch("enquiry")
            const clients = await _fetch("client")

            if (enquiries.success && clients.success) {
                setEnquiries(enquiries.response)
                setClients(clients.response)
                setState("success")
            } else if (!enquiries.success) {
                message.error(enquiries.message)
                setState("error")
            } else {
                message.error(clients.message)
                setState("error")
            }
        } catch (err) {
            message.error("Something went wrong while fetching enquiries");
            setState("error");
        }
    }

    const columns = [
        {
            title: "Client Name",
            dataIndex: "client.name",
            key: "client.name",
            render: (text, record) => (
                <>
                    {
                        record?.client?.name ? (
                            <Link
                                to={`/clients?_id=${record.client._id}`}
                            >
                                {record.client.name}
                            </Link>
                        ) : (
                            <Typography.Text
                                type="secondary"
                            >
                                No client assigned
                            </Typography.Text>
                        )
                    }
                </>
            )
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Response",
            dataIndex: "response",
            key: "response",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: Object.values(consts?.enquiry_statuses)?.map(status => {
                return {
                    text: status,
                    value: status
                }
            }),
            onFilter: (value, record) => record.status === value,
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => moment(text).format("DD MMM YYYY hh:mm A"),
            sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        setSelectedEnquiry(record);
                    }}
                >
                    Edit
                </Button>
            ),
        }
    ];

    async function handleAddEnquiry(val){
        try {
            setState("loading");
            const response = await _fetch("enquiry", {
                method: "POST",
                body: val
            })

            if (response.success) {
                message.success(response.message);
                setAddEnquiryModalVisible(false);
                setEnquiries((prev) => [response.response, ...prev]);
                setState("success");
            } else {
                setState("error");
                message.error(response.message);
            }
        } catch (err) {
            setState("error");
            message.error("Something went wrong while creating enquiry");
        }
    }

    async function updateEnquiry(val){
        try {
            setState("loading");
            const difference = Object.keys(val).reduce((acc, key) => {
                if (key === "client"){
                    if (val[key] !== selectedEnquiry[key]?._id){
                        acc[key] = val[key];
                    }
                } else if (val[key] !== selectedEnquiry[key]){
                    acc[key] = val[key];
                }
                return acc;
            }
            , {});
            console.log(difference)
            const response = await _fetch(`enquiry/${selectedEnquiry._id}`, {
                method: "PATCH",
                body: difference
            })
            if (response.success) {
                message.success(response.message);
                setAddEnquiryModalVisible(false);
                setSelectedEnquiry({});
                setEnquiries((prev) => prev.map(enquiry => {
                    if (enquiry._id === selectedEnquiry._id) {
                        return response.response
                    }
                    return enquiry
                }));
                setState("success");
            } else {
                setState("error");
                message.error(response.message);
            }
        } catch (err) {
            console.error(err)
            setState("error");
            message.error("Something went wrong while updating enquiry");
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <div className="flex-1 w-full">
            <div
                className="flex justify-between items-center"
            >
                <Typography.Title
                    level={2}
                    style={{
                        margin: 0
                    }}
                >
                    Manage Enquiries
                </Typography.Title>
                <Button
                    type="dashed"
                    icon={
                        <PlusOutlined />
                    }
                    loading={state === "loading"}
                    onClick={() => setAddEnquiryModalVisible(true)}
                >
                    Create Enquiry
                </Button>
            </div>
            <div
                className="mt-4"
            >
                <Table
                    columns={columns}
                    dataSource={enquiries}
                    loading={state === "loading"}
                    rowKey="_id"
                    pagination={{
                        pageSize: 10
                    }}
                    bordered
                    scroll={{
                        x: true
                    }}
                />
            </div>
            <Modal
                title={addEnquiryModalVisible ? "Create Enquiry" : "Edit Enquiry"}
                open={addEnquiryModalVisible || selectedEnquiry?._id}
                onCancel={() => {
                    setAddEnquiryModalVisible(false)
                    setSelectedEnquiry({})
                }}
                footer={null}
                destroyOnClose
            >
                <Form
                    name={addEnquiryModalVisible ? "add-enquiry" : "edit-enquiry"}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        addEnquiryModalVisible ? {
                            status: consts?.enquiry_statuses?.ACTION_PENDING
                        } : {
                            ...selectedEnquiry,
                            client: selectedEnquiry?.client?._id,
                        }
                    }
                    onFinish={
                        addEnquiryModalVisible ? handleAddEnquiry : updateEnquiry
                    }
                    className="md:min-w-[400px]"
                >
                    {
                        (!selectedEnquiry?._id || !selectedEnquiry?.client?._id) && (
                            <Form.Item
                                label="Client"
                                name="client"
                            >
                                <Select
                                    showSearch
                                    placeholder = "Select a client"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        clients.map(client => {
                                            return (
                                                <Select.Option
                                                    key={client._id}
                                                    value={client._id}
                                                >
                                                    {client.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        )
                    }
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a title"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Enter title"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a description"
                            }
                        ]}
                    >
                        <Input.TextArea
                            placeholder="Enter description"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please enter an email"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Enter email"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a phone"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Enter phone"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Response"
                        name="response"
                        rules={
                            !selectedEnquiry?._id ? [] : [
                                {
                                    required: true,
                                    message: "Please enter a response"
                                }
                            ]
                        }
                    >
                        <Input.TextArea
                            placeholder="Enter response"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: "Please select a status"
                            }
                        ]}
                    >
                        <Select>
                            {
                                Object.values(consts?.enquiry_statuses)?.map(status => {
                                    return (
                                        <Select.Option
                                            key={status}
                                            value={status}
                                        >
                                            {status}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state === "loading"}
                            className="w-full"
                        >
                            {
                                addEnquiryModalVisible ? "Create Enquiry" : "Update Enquiry"
                            }
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}