import { createSlice } from "@reduxjs/toolkit";

const initialConstState = {}

const constSlice = createSlice({
    name: "const",
    initialState: initialConstState,
    reducers: {
        set: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    set : setConst,
} = constSlice.actions;

export default constSlice.reducer;