import {
    Typography,
} from 'antd'
import HomeBGImage from '../../assets/images/home-bg.jpeg'
import { Link } from 'react-router-dom'

export default function PublicUser(){
    return (
        <div
            className='flex-1 w-full h-full flex flex-col items-center justify-center bg-cover bg-center px-1'
            style={{
                backgroundImage: `url(${HomeBGImage})`
            }}
        >
            <Typography.Title
                level={1}
                style={{
                    color: 'white',
                    fontSize: '6rem',
                    margin: 0
                }}
                className='text-center'
            >
                Coming Soon
            </Typography.Title>
            <Typography.Text
                className='text-center w-full md:w-1/2 mt-4'
                style={{
                    color: 'white',
                    fontSize: '1.5rem'
                }}
            >
                We are working on something awesome, stay tuned! In the meantime, if you are a vendor and would like to join our platform, <Link to='/register-as-vendor'>Click Here</Link>
            </Typography.Text>
        </div>
    )
}