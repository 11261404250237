import { Button, Form, Input, Modal, Space, Table, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PlusOutlined, SearchOutlined, MinusCircleOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/fetch";


export default function Clients(){
    const [searchParams,] = useSearchParams();
    const _id = searchParams.get("_id") || "";
    const _fetch = useFetch();
    const [state, setState] = useState("loading"); // ["success", "loading", "error"]
    const [clients, setClients] = useState([]);
    const [filters, setFilters] = useState({
        name: "",
        gst_number: "",
        emails: []
    });
    const [selectedClient, setSelectedClient] = useState({});
    const [addClientModalVisible, setAddClientModalVisible] = useState(false);

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        if (dataIndex === "emails") {
            setFilters((prev) => ({
                ...prev,
                emails: selectedKeys
            }));
        } else {
            setFilters((prev) => ({
                ...prev,
                [dataIndex]: selectedKeys[0]
            }));
        }
    }

    function handleReset(clearFilters) {
        clearFilters();
        setFilters((prev) => ({
            ...prev,
            name: "",
            gst_number: "",
            emails: []
        }));
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        )
    });

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps("name")
        },
        {
            title: "GST Number",
            dataIndex: "gst_number",
            key: "gst_number",
            ...getColumnSearchProps("gst_number")
        },
        {
            title: "GST Address",
            dataIndex: "gst_address",
            key: "gst_address",
        },
        {
            title: "Emails",
            dataIndex: "emails",
            key: "emails",
            render: (emails) => emails?.length > 0 ? emails.join(", ") : "N/A",
            ...getColumnSearchProps("emails")
        },
        {
            title: "Phone Numbers",
            dataIndex: "phones",
            key: "phones",
            render: (phones) => phones?.length > 0 ? phones.join(", ") : "N/A",
        },
        {
            title: "Payment Terms",
            dataIndex: "payment_terms",
            key: "payment_terms",
            sorter: (a, b) => a.payment_terms - b.payment_terms,
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        setSelectedClient(record);
                    }}
                >
                    Edit
                </Button>
            ),
        }
    ]

    async function init(){
        try {
            setState("loading");
            let url = "client";
            let queryAdded = false;
            if (filters.name){
                url += `?name=${filters.name}`;
                queryAdded = true;
            }
            if (filters.gst_number){
                url += `${queryAdded ? "&" : "?"}gst_number=${filters.gst_number}`;
                queryAdded = true;
            }
            if (filters.emails?.length > 0){
                url += `${queryAdded ? "&" : "?"}emails=${JSON.stringify(filters.emails)}`;
            }
            const res = await _fetch(url);
            if (res.success) {
                setState("success");
                if (_id !== ""){
                    setClients(res.response.filter((client) => client._id === _id));
                } else setClients(res.response);
            } else {
                message.error(res.message);
                setState("error");
            }
        } catch (err) {
            message.error("Something went wrong while fetching clients");
            setState("error");
        }
    }

    async function handleAddClient(val){
        try {
            setState("loading");
            const res = await _fetch("client", {
                method: "POST",
                body: val
            });
            if (res.success) {
                message.success(res.message);
                setAddClientModalVisible(false);
                setClients((prev) => [...prev, res.response]);
                setState("success");
            } else {
                message.error(res.message);
                setState("error");
            }
        } catch (err) {
            console.error(err);
            message.error("Something went wrong while adding client");
            setState("error");
        }
    }

    async function handleUpdateClient(val){
        try {
            setState("loading");
            const difference = Object.keys(val).reduce((acc, key) => {
                if (Array.isArray(val[key])){
                    if (val[key].join(",") !== selectedClient[key].join(",")){
                        acc[key] = val[key];
                    }
                    return acc;
                } else if (val[key] !== selectedClient[key]){
                    acc[key] = val[key];
                }
                return acc;
            }
            , {});
            const res = await _fetch(`client/${selectedClient._id}`, {
                method: "PATCH",
                body: difference
            });
            if (res.success) {
                message.success(res.message);
                setAddClientModalVisible(false);
                setSelectedClient({});
                setClients((prev) => {
                    const index = prev.findIndex((client) => client._id === selectedClient._id);
                    prev[index] = {
                        ...prev[index],
                        ...difference
                    };
                    return [...prev];
                });
                setState("success");
            } else {
                message.error(res.message);
                setState("error");
            }
        } catch (err) {
            console.error(err);
            message.error("Something went wrong while updating client");
            setState("error");
        }
    }

    useEffect(() => {
        init();
    }, [filters, _id]);

    return (
        <div className="flex-1 w-full">
            <div
                className="flex justify-between items-center"
            >
                <Typography.Title
                    level={2}
                    style={{
                        margin: 0
                    }}
                >
                    Manage Clients
                </Typography.Title>
                <Button
                    type="dashed"
                    icon={
                        <PlusOutlined />
                    }
                    loading={state === "loading"}
                    onClick={() => setAddClientModalVisible(true)}
                >
                    Add Client
                </Button>
            </div>
            <div
                className="mt-4"
            >
                <Table
                    columns={columns}
                    dataSource={clients}
                    loading={state === "loading"}
                    rowKey="_id"
                    pagination={{
                        pageSize: 10
                    }}
                    bordered
                    scroll={{
                        x: true
                    }}
                />
            </div>
            <Modal
                title={addClientModalVisible ? "Add Client" : "Edit Client"}
                open={addClientModalVisible || selectedClient?._id}
                onCancel={() => {
                    setAddClientModalVisible(false);
                    setSelectedClient({});
                }}
                footer={null}
                destroyOnClose
            >
                <Form
                    name={addClientModalVisible ? "add-client" : "edit-client"}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        addClientModalVisible ? {} : selectedClient
                    }
                    onFinish={
                        addClientModalVisible ? handleAddClient : handleUpdateClient
                    }
                    className="md:min-w-[400px]"
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please enter client name"
                            }
                        ]}
                    >
                        <Input placeholder="Client Name"/>
                    </Form.Item>
                    <Form.Item
                        label="GST Number"
                        name="gst_number"
                        rules={[
                            {
                                required: true,
                                message: "Please enter GST number"
                            }
                        ]}
                    >
                        <Input placeholder="GST Number"/>
                    </Form.Item>
                    <Form.Item
                        label="GST Address"
                        name="gst_address"
                        rules={[
                            {
                                required: true,
                                message: "Please enter GST address"
                            }
                        ]}
                    >
                        <Input placeholder="GST Address"/>
                    </Form.Item>
                    <Form.List
                        name="emails"
                        rules={[
                            {
                            validator: async (_, emails) => {
                                if (!emails || emails.length < 1) {
                                message.error("Please add at least one email");
                                return Promise.reject("Please add at least one email");
                                } else {
                                    return Promise.resolve();
                                }
                            },
                            },
                        ]}
                        >
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(({ key, name, ...restField }) => (
                                <div className="flex items-baseline gap-x-4" key={key}>
                                <Form.Item
                                    {...restField}
                                    label="Email"
                                    name={[name]}
                                    rules={[
                                    {
                                        required: true,
                                        message: "Please input your email!",
                                    },
                                    ]}
                                    className="flex-1"
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                                >
                                    Add Email
                                </Button>
                            </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.List
                        name="phones"
                        rules={[
                            {
                            validator: async (_, phones) => {
                                if (!phones || phones.length < 1) {
                                message.error("Please add at least one phone number");
                                }
                            },
                            },
                        ]}
                        >
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(({ key, name, ...restField }) => (
                                <div className="flex items-baseline gap-x-4" key={key}>
                                <Form.Item
                                    {...restField}
                                    label="Phone Number"
                                    name={[name]}
                                    rules={[
                                    {
                                        required: true,
                                        message: "Please input your phone number!",
                                    },
                                    ]}
                                    className="flex-1"
                                >
                                    <Input placeholder="Phone Number" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                                >
                                Add Phone Number
                                </Button>
                            </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item
                        label="Payment Terms"
                        name="payment_terms"
                        rules={[
                            {
                                required: true,
                                message: "Please enter payment terms"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Payment Terms"
                            suffix="days"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="w-full"
                            loading={state === "loading"}
                        >
                            {addClientModalVisible ? "Add Client" : "Update Client"}
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    )
}