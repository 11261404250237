import { Button, Form, InputNumber, Modal, Select, Table, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/fetch";

export default function Stocks() {
  const _fetch = useFetch();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [stocks, setStocks] = useState([]);
  const [stockConsts, setStockConsts] = useState({});
  const [selectedStock, setSelectedStock] = useState({});
  const [addStockModalVisible, setAddStockModalVisible] = useState(false);
  const [newMaterialSearchValue, setNewMaterialSearchValue] = useState("");
  const [newPlantSearchValue, setNewPlantSearchValue] = useState("");

  async function init(type = "global") {
    try {
      const stockRes = await _fetch("stock");
      const stockConstsRes = await _fetch("stock/consts");
      if (stockRes.success && stockConstsRes.success) {
        setStocks(stockRes.response);
        setStockConsts(stockConstsRes.response);
        if (type === "global") setState("success");
      } else {
        if (type === "global") {
          setState("error");
          message.error(stockRes.message);
        }
      }
    } catch (error) {
      console.error(error);
      if (type === "global") setState("error");
    }
  }

  const columns = [
    {
      title: "Raw Material",
      dataIndex: "material",
      key: "material",
      filters: stockConsts?.materials?.map((material) => ({
        text: material,
        value: material,
      })),
      onFilter: (value, record) =>
        record.material?.toLowerCase() === value?.toLowerCase(),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
	  sorter: (a, b) => a.quantity - b.quantity,
	  render: (text) => (
		<span>{text} Kg</span>
	  )
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
      filters: stockConsts?.plants?.map((plant) => ({
        text: plant,
        value: plant,
      })),
      onFilter: (value, record) =>
        record.plant?.toLowerCase() === value?.toLowerCase(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          title="Edit User"
          type="primary"
          onClick={() => {
            setSelectedStock(record);
          }}
          icon={<EditOutlined />}
        >
          Edit Stock
        </Button>
      ),
    },
  ];

  async function handleAddStock(val) {
    try {
      setState("loading");
      const res = await _fetch("stock", {
        method: "POST",
        body: val,
      });
      if (res.success) {
        await init("local");
        message.success(res.message);
        setState("success");
        setAddStockModalVisible(false);
		setSelectedStock({});
		setNewMaterialSearchValue("");
		setNewPlantSearchValue("");
      } else {
        setState("error");
        message.error(res.message);
      }
    } catch (error) {
      setState("error");
      message.error("Something went wrong while adding stock");
    }
  }

  async function handleUpdateStock(val){
	try {
		setState("loading");
		const difference = Object.keys(val).reduce((acc, key) => {
			if(val[key] !== selectedStock[key]){
				acc[key] = val[key];
			}
			return acc;
		},{});
		const res = await _fetch(`stock/${selectedStock._id}`, {
			method: "PATCH",
			body: difference,
		});
		if (res.success) {
			await init("local");
			message.success(res.message);
			setState("success");
			setAddStockModalVisible(false);
			setSelectedStock({});
			setNewMaterialSearchValue("");
			setNewPlantSearchValue("");
		} else {
			setState("error");
			message.error(res.message);
		}
	} catch (error) {
		setState("error");
		message.error("Something went wrong while updating stock");
	}
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="flex-1 w-full">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Manage Stock
        </Typography.Title>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          loading={state === "loading"}
          onClick={() => setAddStockModalVisible(true)}
        >
          Add Stock
        </Button>
      </div>
      <div className="mt-4">
        <Table
          columns={columns}
          dataSource={stocks}
          loading={state === "loading"}
          rowKey="_id"
          pagination={{
            pageSize: 10,
          }}
          bordered
          scroll={{
            x: true,
          }}
        />
      </div>
      <Modal
        title={addStockModalVisible ? "Add Stock" : "Edit Stock"}
        open={addStockModalVisible || selectedStock?._id}
        onCancel={() => {
          setAddStockModalVisible(false);
          setSelectedStock({});
        }}
        footer={null}
        destroyOnClose
      >
		<Form
			name={addStockModalVisible ? "add-stock" : "edit-stock"}
			labelCol={{
				span: 24,
			}}
			wrapperCol={{
				span: 24,
			}}
			initialValues={
				addStockModalVisible ? {} : selectedStock
			}
			onFinish={
				addStockModalVisible ? handleAddStock : handleUpdateStock
			}
			className="md:min-w-[400px]"
		>
			<Form.Item
				label="Material"
				name="material"
				rules={[
					{
						required: true,
						message: "Please select a material",
					},
				]}
			>
				<Select
					showSearch
					placeholder="Select a material or add a new one"
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					onSearch={(val) => setNewMaterialSearchValue(val)}					
				>
					{stockConsts?.materials?.map((material) => (
						<Select.Option value={material} key={material}>
							{material}
						</Select.Option>
					))}
					{
						!stockConsts?.materials?.some((curMaterial) => curMaterial?.toLowerCase() === newMaterialSearchValue?.toLowerCase()) && newMaterialSearchValue && (
							<Select.Option value={newMaterialSearchValue} key={newMaterialSearchValue}>
								{newMaterialSearchValue}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			<Form.Item
				label="Quantity"
				name="quantity"
				rules={[
					{
						required: true,
						message: "Please enter the quantity",
					},
				]}
			>
				<InputNumber
					min={0}
					placeholder="Enter the quantity"
					className="w-full"
					suffix="Kg"
				/>
			</Form.Item>
			<Form.Item
				label="Plant"
				name="plant"
				rules={[
					{
						required: true,
						message: "Please select a plant",
					},
				]}
			>
				<Select
					showSearch
					placeholder="Select a plant"
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					onSearch={(val) => setNewPlantSearchValue(val)}
					
				>
					{stockConsts?.plants?.map((plant) => (
						<Select.Option value={plant} key={plant}>
							{plant}
						</Select.Option>
					))}
					{
						!stockConsts?.plants?.some((curPlant) => curPlant?.toLowerCase() === newPlantSearchValue?.toLowerCase()) && newPlantSearchValue && (
							<Select.Option value={newPlantSearchValue} key={newPlantSearchValue}>
								{newPlantSearchValue}
							</Select.Option>
						)
					}
				</Select>
			</Form.Item>
			<Form.Item>
				<Button
					type="primary"
					htmlType="submit"
					loading={state === "loading"}
					className="w-full"
				>
					{
						addStockModalVisible ? "Add Stock" : "Update Stock"
					}
				</Button>
			</Form.Item>
		</Form>

	  </Modal>
    </div>
  );
}
