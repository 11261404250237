import { Layout } from "antd"

import AuctionHeader from "./header"
import AuctionFooter from "./footer"


const {
    Content
} = Layout

export default function AuctionMainLayout({
    children,
}){
    return (
        <Layout
            className="min-h-screen h-auto w-screen flex flex-col items-center justify-between"
        >
            <AuctionHeader />
            <Content
                className={`flex flex-col items-center justify-center grow container py-4`}
            >
                {children}
            </Content>
            <AuctionFooter />
        </Layout>
    )
}