import { useEffect, useState } from 'react';
import moment from 'moment';
import {
    PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Spin, Typography, message } from 'antd';
import useAuctionFetch from '../hooks/fetch';
import BadgeSVG from '../../assets/svgs/badge.svg';

export default function AuctionDetails(){
    const _fetch = useAuctionFetch();
    const [state, setState] = useState("loading"); // ["success", "loading", "error"]
    const [auction, setAuction] = useState({});
    const [addBidExpanded, setAddBidExpanded] = useState(false);
    const [deleteBid, setDeleteBid] = useState(null);

    async function init(){
        try {
            const res = await _fetch("auction");
            if (res.success) {
                setAuction(res.response);
                setState("success");
            } else {
                setState("error");
            }
        } catch (err) {
            console.error(err);
            setState("error");
        }
    }

    async function handleDeleteBid(){
        try {
            setState("loading");
            const res = await _fetch(`auction/delete-bid/${deleteBid}`, {
                method: "DELETE",
            });
            if (res.success) {
                setDeleteBid(null);
                setAuction((prev) => ({
                    ...prev,
                    bid: null,
                }));
                setState("success");
                message.success(res.message);
            } else {
                setState("error");
                message.error(res.message);
            }
        } catch (err) {
            console.error(err);
            setState("error");
        }
    }

    async function handleAddBid(val){
        try {
            setState("loading");
            const res = await _fetch(`auction/add-bid/${auction._id}`, {
                method: "POST",
                body: val
            });
            if (res.success) {
                setAuction((prev) => ({
                    ...prev,
                    bid: res.response,
                }));
                setAddBidExpanded(false);
                setState("success");
                message.success(res.message);
            } else {
                setState("error");
                message.error(res.message);
            }
        } catch (err) {
            console.error(err);
            setState("error");
        }
    }

    useEffect(() => {
        init();
    }, []);

    if (state === "loading") {
        return (
            <Spin />
        )
    }

    return (
        <div className="flex-1 w-full">
            <div className="flex justify-between items-center">
                <Typography.Title
                    level={2}
                    style={{
                        margin: 0,
                    }}
                >
                    {auction.id}
                </Typography.Title>
                <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    loading={state === "loading"}
                    onClick={() => {
                        setAddBidExpanded(true);
                    }}
                    disabled={addBidExpanded || auction?.bid?._id}
                >
                    Add Bid
                </Button>
            </div>
            <div>
                <Row gutter={[16, 16]} className="my-4 md:my-8">
                    <Col
                        xs={{
                            span: 24,
                            order: 2
                        }}
                        sm={{
                            span: 12,
                            order: 1
                        }}
                    >
                        <div>
                        <Typography.Title level={4}>Auction Information</Typography.Title>
                        <Row gutter={[16, 16]} className="my-4 md:my-8">
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Auction Name:</b> {auction.name}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Auction Description:</b> {auction.description}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Start Date:</b>{" "}
                                {moment(auction.startDate).format("DD MMM YYYY hh:mm A")}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>End Date:</b>{" "}
                                {moment(auction.endDate).format("DD MMM YYYY hh:mm A")}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Minimum Bid:</b> {auction.minimumBid} {"INR"}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Status:</b> {auction.status}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Item Name:</b> {auction.item?.name}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Item Description:</b> {auction.item?.description}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Text>
                                <b>Item Quantity:</b> {auction.item?.quantity}{" "}
                                {auction.item?.unit}
                                </Typography.Text>
                            </Col>
                        </Row>
                    </div>
                    </Col>
                    <Col
                        xs={{
                            span: 24,
                            order: 1
                        }}
                        sm={{
                            span: 12,
                            order: 2
                        }}
                        className='flex'
                    >
                        <div
                            className='flex-1 w-full flex justify-center items-center flex-col'
                        >
                            {
                                auction?.bid?._id ? (
                                    <div>
                                        <div
                                            className='relative select-none flex items-center justify-center'
                                        >
                                            <img
                                                src={BadgeSVG}
                                                alt="Badge"
                                                className='w-48 h-48 md:w-72 md:h-72 z-0 select-none'
                                            />
                                            <Typography.Title
                                                level={1}
                                                className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 select-none text-center'
                                                style={{
                                                    margin: 0,
                                                    fontSize: "6.5rem",
                                                }}
                                            >
                                                {auction?.bid?.rank}
                                            </Typography.Title>
                                        </div>
                                        <Typography.Title
                                            level={3}
                                            style={{
                                                margin: 0,
                                            }}
                                            className='text-center'
                                        >
                                            {auction?.bid?.amount} {"₹"}
                                        </Typography.Title>
                                        <Typography.Text
                                            className='text-center'
                                        >
                                            Congratulations! You have placed a bid. <Button type="link" className='p-0' onClick={() => setDeleteBid(auction?.bid?._id)}>Delete Bid</Button>
                                        </Typography.Text>
                                    </div>
                                ) : (
                                    addBidExpanded ? (
                                        <div
                                            className='w-full flex justify-center items-center flex-col'
                                        >
                                            <Form
                                                name='add-bid'
                                                layout='vertical'
                                                onFinish={handleAddBid}
                                                className='max-w-[300px] w-full'
                                            >
                                                <Form.Item
                                                    label='Amount'
                                                    name={["bid", "amount"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter the amount!',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type='number'
                                                        className='w-full'
                                                        placeholder='Enter the amount'
                                                        min={auction.minimumBid}
                                                        suffix='₹'
                                                    />
                                                </Form.Item>
                                                <div
                                                    className='flex justify-between gap-x-4 items-center w-full mt-8'
                                                >
                                                    <Button
                                                        type='primary'
                                                        htmlType='submit'
                                                        loading={state === "loading"}
                                                        className='w-full rounded-full'
                                                    >
                                                        Place Bid
                                                    </Button>
                                                    <Button
                                                        type='primary'
                                                        danger
                                                        className='w-full rounded-full'
                                                        onClick={() => setAddBidExpanded(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    ) : (
                                        <Typography.Text>
                                            Since you haven't placed any bids yet, why not make your move to secure your rank? 🥇
                                        </Typography.Text>
                                    )
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title="Are you sure you want to delete your bid?"
                open={deleteBid !== null}
                onCancel={() => setDeleteBid(null)}
                footer={null}
            >
                <div>
                    <Typography.Text>
                        Are you sure you want to delete your bid? This action cannot be undone. However, you can place a new bid.
                    </Typography.Text>
                    <div
                        className='flex justify-between gap-x-4 items-center w-full mt-8'
                    >
                        <Button
                            type='primary'
                            className='w-full rounded-full'
                            danger
                            loading={state === "loading"}
                            onClick={handleDeleteBid}
                        >
                            Confirm
                        </Button>
                        <Button
                            type='primary'
                            onClick={() => setDeleteBid(null)}
                            className='w-full rounded-full'
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}