import { useState } from "react";
import { Button, Form, Input, App, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import useFetch from "../../../hooks/fetch";

export default function RegisterAsVendor() {
  const navigate = useNavigate();
  const vendor_types = {
    MANUFACTURER: "Manufacturer",
    TRADER: "Trader"
  };
  const { message } = App.useApp();
  const _fetch = useFetch();
  const [state, setState] = useState("success"); // ["success", "loading", "error"]
  

  async function submitForm(val) {
    try {
      setState("loading");
      const res = await _fetch("user/vendor", {
        method: "POST",
        body: val,
      });
      if (res.success) {
        message.success(res.message);
        setState("success");
        navigate("/login");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong");
      setState("error");
    }
  }

  return (
    <div>
      <h1 className="text-center text-2xl font-bold mb-8">
        Register as Vendor
      </h1>
      <Form
        name="register-as-vendor"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          type: vendor_types.MANUFACTURER,
        }}
        onFinish={submitForm}
        autoComplete="off"
        className="min-w-[300px] md:min-w-[400px] lg:min-w-[500px]"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="GST Number"
          name="gst_number"
          rules={[
            {
              required: true,
              message: "Please input your GST number!",
            },
          ]}
        >
          <Input placeholder="GST Number" />
        </Form.Item>
        <Form.Item
          label="GST Address"
          name="gst_address"
          rules={[
            {
              required: true,
              message: "Please input your GST address!",
            },
          ]}
        >
          <Input placeholder="GST Address" />
        </Form.Item>
        <Form.Item name="type" label="Type">
            <Select placeholder="Select Type">
              {Object.keys(vendor_types).map((key) => (
                <Select.Option key={key} value={vendor_types[key]}>
                  {vendor_types[key]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        <Form.List
          name="emails"
          rules={[
            {
              validator: async (_, emails) => {
                if (!emails || emails.length < 1) {
                  message.error("Please add at least one email");
                  return Promise.reject("Please add at least one email");
                } else {
                    return Promise.resolve();
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div className="flex items-baseline gap-x-4" key={key}>
                  <Form.Item
                    {...restField}
                    label="Email"
                    name={[name]}
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                    className="flex-1"
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Email
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.List
          name="phones"
          rules={[
            {
              validator: async (_, phones) => {
                if (!phones || phones.length < 1) {
                  message.error("Please add at least one phone number");
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div className="flex items-baseline gap-x-4" key={key}>
                  <Form.Item
                    {...restField}
                    label="Phone Number"
                    name={[name]}
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                    className="flex-1"
                  >
                    <Input placeholder="Phone Number" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Phone Number
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
            name={['bank_details', 'account_number']}
            label="Account Number"
            rules={[
              {
                required: true,
                message: "Please input your account number!",
              },
            ]}
        >
            <Input placeholder="Account Number" />
        </Form.Item>
        <Form.Item
            name={['bank_details', 'ifsc_code']}
            label="IFSC"
            rules={[
              {
                required: true,
                message: "Please input your IFSC!",
              },
            ]}
        >
            <Input placeholder="IFSC" />
        </Form.Item>
        <Form.Item
            name={['bank_details', 'bank_name']}
            label="Bank Name"
            rules={[
              {
                required: true,
                message: "Please input your bank na,e!",
              },
            ]}
        >
            <Input placeholder="Bank Name" />
        </Form.Item>
        <Form.Item
            name={['bank_details', 'branch_name']}
            label="Branch Name"
            rules={[
              {
                required: true,
                message: "Please input your branch name!",
              },
            ]}
        >
            <Input placeholder="Branch Name" />
        </Form.Item>
        <Form.Item
            name={['bank_details', 'account_holder_name']}
            label="Account Holder Name"
            rules={[
              {
                required: true,
                message: "Please input your account holder name!",
              },
            ]}
        >
            <Input placeholder="Account Holder Name" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={state === "loading"}
            className="w-full"
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
