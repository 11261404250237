import { Button, Form, Input, Rate, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../../hooks/fetch";

export default function CreateFeedback() {
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order") || "";
  const client = searchParams.get("client") || "";
  const navigate = useNavigate();
  const _fetch = useFetch();

  const [state, setState] = useState("success"); // ["success", "loading", "error"]

  async function submitFeedback(val) {
    try {
      setState("loading");
      const res = await _fetch("feedback", {
        method: "POST",
        body: {
          ...val,
          order,
          client,
        },
      });
      if (res.success) {
        message.success("Feedback submitted successfully");
        setState("success");
        navigate("/");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      message.error("Something went wrong");
      console.error(error);
      setState("error");
    }
  }

  useEffect(() => {
    if (!order || !client) {
      navigate("/");
    }
  }, [order, client]);

  return (
    <div>
      <Typography.Title level={2} className="text-center font-bold">
        Submit Feedback
      </Typography.Title>
      <Typography.Text className="text-center">
        We are happy to hear that you received your order. Please let us know
        how we did.
      </Typography.Text>
      <Form
        name="feedback"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={submitFeedback}
        className="md:min-w-[400px]"
      >
        <Form.Item
            label="Rating"
            name="rating"
            rules={[
                {
                required: true,
                message: "Please select a rating",
                },
            ]}
        >
            <Rate
                allowHalf
                defaultValue={0}
            />
        </Form.Item>
        <Form.Item
          label="Feedback"
          name="comment"
          rules={[
            {
              required: true,
              message: "Please provide feedback",
            },
          ]}
        >
            <Input.TextArea
                placeholder="Feedback"
                autoSize={{ minRows: 3 }}
            />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={state === "loading"}
            className="w-full"
          >
            Submit Feedback
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
