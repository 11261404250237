import {
  Button,
  Form,
  Modal,
  Select,
  Table,
  Typography,
  message,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/fetch";
import { useConst } from "../../hooks/const";
import { useUser } from "../../hooks/user";

export default function Users() {
  const _fetch = useFetch();
  const consts = useConst();
  const { _id, role = null } = useUser();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);

  async function init() {
    try {
      const res = await _fetch("user/get-users");
      if (res.success) {
        setUsers(res.response);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.log("Error", error);
      message.error("Failed to fetch users");
      setState("error");
    }
  }

  async function handleAddUser(val) {
    try {
      setState("loading");
      const url =
        val?.role === consts?.user_roles?.ADMIN
          ? "user/create-director"
          : "user/create-user";
      const res = await _fetch(url, {
        method: "POST",
        body: val,
      });
      if (res.success) {
        message.success(res.message);
        setUsers((prev) => [...prev, res.response]);
        setSelectedUser({});
        setAddUserModalVisible(false);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.log("Error", error);
      message.error("Failed to add user");
      setState("error");
    }
  }

  async function handleEditUser(val) {
    try {
      setState("loading");
      const difference = Object.keys(val).reduce((acc, key) => {
        if (key === "password") {
          if (val[key]) {
            acc[key] = val[key];
          }
        } else {
          if (val[key] !== selectedUser[key]) {
            acc[key] = val[key];
          }
        }
        return acc;
      }, {});
      const res = await _fetch(`user/${selectedUser?._id}`, {
        method: "PATCH",
        body: difference,
      });
      if (res.success) {
        message.success(res.message);
        setUsers((prev) => {
          const index = prev.findIndex((user) => user._id === selectedUser._id);
          prev[index] = res.response;
          return [...prev];
        });
        setSelectedUser({});
        setAddUserModalVisible(false);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.log("Error", error);
      message.error("Failed to edit user");
      setState("error");
    }
  }

  async function handleDeleteUser(userId) {
    try {
      setState("loading");
      const res = await _fetch(`user/${userId}`, {
        method: "DELETE",
      });
      if (res.success) {
        message.success(res.message);
        setState("success");
        setUsers((prev) => prev.filter((user) => user._id !== userId));
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.log("Error", error);
      message.error("Failed to delete user");
      setState("error");
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      filters: consts?.user_roles
        ? Object.values(consts?.user_roles).map((role) => ({
            text: role,
            value: role,
          }))
        : [],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center gap-x-4">
          <Button
            title="Edit User"
            type="primary"
            onClick={() => {
              setSelectedUser(record);
            }}
            disabled={
              (role !== consts?.user_roles?.ADMIN &&
                record?.role === consts?.user_roles?.ADMIN) ||
              _id === record?._id
            }
            icon={<EditOutlined />}
          />
          <Button
            title="Delete User"
            type="primary"
            danger
            onClick={() => {
              handleDeleteUser(record._id);
            }}
            disabled={
              (role !== consts?.user_roles?.ADMIN &&
                record?.role === consts?.user_roles?.ADMIN) ||
              _id === record?._id
            }
            icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="flex-1 w-full">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Manage Users
        </Typography.Title>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          loading={state === "loading"}
          onClick={() => setAddUserModalVisible(true)}
        >
          Add User
        </Button>
      </div>
      <div className="mt-4">
        <Table
          columns={columns}
          dataSource={users}
          loading={state === "loading"}
          rowKey="_id"
          pagination={{
            pageSize: 10,
          }}
          bordered
          scroll={{
            x: true,
          }}
        />
      </div>
      <Modal
        title={addUserModalVisible ? "Add User" : "Edit User"}
        open={addUserModalVisible || selectedUser?._id}
        onCancel={() => {
          setAddUserModalVisible(false);
          setSelectedUser({});
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          name={addUserModalVisible ? "add-user" : "edit-user"}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            addUserModalVisible
              ? {}
              : {
                  ...selectedUser,
                  password: "",
                }
          }
          onFinish={addUserModalVisible ? handleAddUser : handleEditUser}
          className="md:min-w-[400px]"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input name",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input email",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Please select role",
              },
            ]}
          >
            <Select placeholder="Select Role">
              {role === consts?.user_roles?.ADMIN
                ? Object.values(consts?.user_roles).map((curRole) => (
                    <Select.Option key={curRole} value={curRole}>
                      {curRole}
                    </Select.Option>
                  ))
                : Object.values(consts?.user_roles)
                    .filter((curRole) => curRole !== consts?.user_roles?.ADMIN)
                    .map((curRole) => (
                      <Select.Option key={curRole} value={curRole}>
                        {curRole}
                      </Select.Option>
                    ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: !selectedUser?._id,
                message: "Please input password for user",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              loading={state === "loading"}
            >
              {addUserModalVisible ? "Add User" : "Edit User"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
