import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import { PlusOutlined, EditOutlined, SearchOutlined, MinusCircleOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/fetch";
import { useConst } from "../../hooks/const";
import { useUser } from "../../hooks/user";
import { canViewPODetails } from "../../services/access-control";

export default function PurchaseOrders() {
  const [searchParams,] = useSearchParams();
  const _id = searchParams.get("_id") || "";
  const _fetch = useFetch();
  const consts = useConst();
  const user = useUser();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedPO, setSelectedPO] = useState({});
  const [addPOModalVisible, setAddPOModalVisible] = useState(false);
  const [filters, setFilters] = useState({
    item_name: "",
  });

  async function init() {
    try {
      setState("loading");
      let url = "purchase-order";
      let queryAdded = false;
      if (filters.item_name) {
        url += `?item_name=${filters.item_name}`;
        queryAdded = true;
      }
      const res = await _fetch(url);
      const res2 = await _fetch(`vendor?status=${consts?.vendor_statuses?.APPROVED}`);
      if (res.success && res2.success) {
        if (_id !== ""){
          setPurchaseOrders(res.response.filter(po => po._id === _id));
        } else {
          setPurchaseOrders(res.response);
        }
        setVendors(res2.response);
        setState("success");
      } else if (!res.success) {
        message.error(res.message);
        setState("error");
      } else {
        message.error(res2.message);
        setState("error");
      }
    } catch (err) {
      console.error(err);
      message.error("Failed to fetch purchase orders");
      setState("error");
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: selectedKeys[0],
    }));
  }

  function handleReset(clearFilters) {
    clearFilters();
    setFilters((prev) => ({
      ...prev,
      item_name: "",
      vendor: "",
    }));
  }

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const columns = [
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      ...getColumnSearchProps("item_name", "Search Item Name"),
      render: (items) => (
        <ul>
          {items.map((item) => (
            <li key={item._id}>
              <p>
                <strong>Name:</strong> {item.name}
              </p>
              <p>
                <strong>Quantity:</strong> {item.quantity} {consts?.units?.purchaseOrder?.quantity}
              </p>
            </li>
          ))}
        </ul>
      ),
      width: "30%",
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (date) => moment(date).format("DD MMM YYYY"),
      sorter: (a, b) =>
        moment(a.delivery_date).unix() - moment(b.delivery_date).unix(),
    },
    {
      title: "Vendor Details",
      dataIndex: "vendor",
      key: "vendor",
      render: (vendor) => {
        const vendorDetails = vendors.find(
          (v) => v._id?.toString() === vendor?.toString()
        );
        return (
          <Link
            to={`/vendors?_id=${vendorDetails?._id}`}
            target="_blank"
            rel="noreferrer"
          >
            <p>{vendorDetails?.name}</p>
            <p>{vendorDetails?.gst_number}</p>
          </Link>
        );
      },
      filters: vendors.map((vendor) => ({
        text: vendor.name,
        value: vendor._id,
      })),
      onFilter: (value, record) => record.vendor === value,
    },
    {
      title: "Payment Terms",
      dataIndex: "payment_terms",
      key: "payment_terms",
      render: (terms) => `${terms} days`,
      sorter: (a, b) => a.payment_terms - b.payment_terms,
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      render: (paid) => (paid ? "Yes" : "No"),
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) => record.paid === value,
    },
    {
      title: "Fulfilled",
      dataIndex: "fulfilled",
      key: "fulfilled",
      render: (fullfilled) => (fullfilled ? "Yes" : "No"),
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) => record.fulfilled === value,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space
          size="middle"
          direction="vertical"
        >
          <Button
            title="Edit Purchase Order"
            type="primary"
            onClick={() => {
              setSelectedPO(record);
            }}
            icon={<EditOutlined />}
          >
            Edit Purchase Order
          </Button>
          {
            canViewPODetails(user, consts) && (
              <Link
                to={`/purchase-orders/${record._id}`}
                target="_blank"
                rel="noreferrer"
              >
                View Purchase Order
              </Link>
            )
          }
        </Space>
      ),
    },
  ];

  async function handleAddPO(val) {
    try {
      setState("loading");
      const res = await _fetch("purchase-order", {
        method: "POST",
        body: {
          ...val,
          delivery_date: moment(val.delivery_date).toISOString(),
          payment_terms: val.payment_terms?.toString(),
        },
      });
      if (res.success) {
        message.success(res.message);
        setPurchaseOrders((prev) => [res.response, ...prev]);
        setAddPOModalVisible(false);
        setSelectedPO({});
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      console.error(err);
      message.error("Failed to create purchase order");
    }
  }

  async function handleUpdatePO(val) {
    try {
      setState("loading");
      const diff = Object.keys(val).reduce((acc, key) => {
        if (key === "delivery_date") {
            const isSameDate = moment(val[key]).isSame(moment(selectedPO[key]));
            if (!isSameDate) {
              acc[key] = val[key];
            }
        } else if (val[key] !== selectedPO[key]) {
          acc[key] = val[key];
        }
        return acc;
      }, {});
      let body = diff;
      if (diff.delivery_date) {
        body = {
          ...body,
          delivery_date: moment(diff.delivery_date).toISOString(),
        };
      }
      if (diff.payment_terms) {
        body = {
          ...body,
          payment_terms: diff.payment_terms?.toString(),
        };
      }
      const res = await _fetch(`purchase-order/${selectedPO._id}`, {
        method: "PATCH",
        body: body,
      });
      if (res.success) {
        message.success(res.message);
        setPurchaseOrders((prev) => {
          const index = prev.findIndex((po) => po._id === selectedPO._id);
          prev[index] = {
            ...prev[index],
            ...diff,
          };
          return [...prev];
        });
        setAddPOModalVisible(false);
        setSelectedPO({});
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      console.error(err);
      message.error("Failed to update purchase order");
    }
  }

  useEffect(() => {
    init();
  }, [filters, _id]);

  return (
    <div className="flex-1 w-full">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Manage Purchase Orders
        </Typography.Title>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          loading={state === "loading"}
          onClick={() => setAddPOModalVisible(true)}
        >
          Create Purchase Order
        </Button>
      </div>
      <div className="mt-4">
        <Table
          columns={columns}
          dataSource={purchaseOrders}
          loading={state === "loading"}
          rowKey="_id"
          pagination={{
            pageSize: 10,
          }}
          bordered
          scroll={{
            x: true,
          }}
        />
      </div>
      <Modal
        title={
          addPOModalVisible ? "Create Purchase Order" : "Edit Purchase Order"
        }
        open={addPOModalVisible || selectedPO?._id}
        onCancel={() => {
          setAddPOModalVisible(false);
          setSelectedPO({});
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          name={addPOModalVisible ? "addPO" : "editPO"}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            addPOModalVisible
              ? {}
              : {
                  ...selectedPO,
                  delivery_date: moment(selectedPO?.delivery_date).format(
                    "YYYY-MM-DD"
                  ),
                }
          }
          onFinish={addPOModalVisible ? handleAddPO : handleUpdatePO}
          className="md:min-w-[400px]"
        >
          <Form.List
            label="Items"
            name="items"
            rules={[
              {
                validator: async (_, items) => {
                  if (!items || items.length < 1) {
                    message.error("Please add at least one item");
                    return Promise.reject("Please add at least one item");
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({key, name, ...restField}) => (
                  <div key={key}
                    className="flex items-baseline gap-x-4"
                  >
                    <Form.Item
                      {...restField}
                      label="Item Name"
                      name={[name, "name"]}
                      rules={[{required: true, message: "Missing item name"}]}
                      className="w-1/2"
                    >
                      <Input placeholder="Item name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Quantity"
                      name={[name, "quantity"]}
                      rules={[{required: true, message: "Missing item quantity"}]}
                      className="w-1/4"
                    >
                      <InputNumber
                        placeholder="Quantity"
                        min={1}
                        suffix={consts?.units?.purchaseOrder?.quantity}
                        className="w-full"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Price"
                      name={[name, "price"]}
                      rules={[{required: true, message: "Missing item price"}]}
                      className="w-1/4"
                    >
                      <InputNumber
                        placeholder="Price"
                        min={1}
                        suffix={consts?.units?.purchaseOrder?.price}
                        className="w-full"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Item
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Delivery Date"
            name="delivery_date"
            rules={[
              {
                required: true,
                message: "Please select delivery date",
              },
            ]}
          >
            {selectedPO?._id ? (
              <Input
                type="date"
                placeholder="Select delivery date"
              />
            ) : (
              <Input
                type="date"
                placeholder="Select delivery date"
                min={moment().add(1, "day").format("YYYY-MM-DD")}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Vendor"
            name="vendor"
            rules={[
              {
                required: true,
                message: "Please select vendor",
              },
            ]}
          >
            <Select
              placeholder="Select vendor"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendors.map((vendor) => (
                <Select.Option value={vendor._id} key={vendor._id}>
                  {vendor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Payment Terms"
            name="payment_terms"
            rules={[
              {
                required: true,
                message: "Please enter payment terms",
              },
            ]}
          >
            <InputNumber
              placeholder="Enter payment terms"
              suffix="days"
              min={1}
              className="w-full"
            />
          </Form.Item>
          <Form.Item label="Paid" name="paid" valuePropName="checked">
            <Checkbox>Paid</Checkbox>
          </Form.Item>
          <Form.Item label="Fulfilled" name="fulfilled" valuePropName="checked">
            <Checkbox>Fulfilled</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full"
            >
              {addPOModalVisible
                ? "Create Purchase Order"
                : "Update Purchase Order"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
