import PublicUser from '../../components/home/PublicUser';
import {
  Spin,
  Row,
  Col,
  Typography
} from 'antd';

import {
  HomeDetails,
  getHomeViewDetails
} from '../../services/access-control';

import { useUser } from '../../hooks/user';
import { useConst } from '../../hooks/const';
import { useEffect, useState } from 'react';
import useFetch from '../../hooks/fetch';
import { Link } from 'react-router-dom';

export default function Home() {
  const user = useUser();
  const consts = useConst();
  const _fetch = useFetch();
  const [state, setState] = useState("success"); // ["success", "loading", "error"]
  const [stats, setStats] = useState([]);

  const homeViewDetails = getHomeViewDetails(user, consts);

  const isPublicUser = !user?._id || !user?.role || homeViewDetails?.length === 0;

  async function init(){
    try {
      const res = await _fetch("stats");
      if (res?.success){
        let updatedStats = [];
        if (homeViewDetails?.includes(HomeDetails.CLIENT)){
          updatedStats.push({
            title: "Clients",
            count: res?.response?.clients,
            link: "/clients",
            text: "Total Clients Registered"
          })
        }
        if (homeViewDetails?.includes(HomeDetails.VENDOR)){
          updatedStats.push({
            title: "Vendors",
            count: res?.response?.vendors,
            link: "/vendors",
            text: "Total Active Vendors"
          })
        }
        if (homeViewDetails?.includes(HomeDetails.ENQUIRY)){
          updatedStats.push({
            title: "Enquiries",
            count: res?.response?.enquiries,
            link: "/enquiries",
            text: "Total Enquiries Created"
          })
        }
        if (homeViewDetails?.includes(HomeDetails.ORDER)){
          updatedStats.push({
            title: "Orders",
            count: res?.response?.orders,
            link: "/orders",
            text: "Total Orders yet to be delivered"
          })
        }
        if (homeViewDetails?.includes(HomeDetails.PURCHASE_ORDER)){
          updatedStats.push({
            title: "Purchase Orders",
            count: res?.response?.purchaseOrders,
            link: "/purchase-orders",
            text: "Total Purchase Orders yet to be paid"
          })
        }
        if (homeViewDetails?.includes(HomeDetails.STOCK)){
          updatedStats.push({
            title: "Stock",
            count: res?.response?.stocks,
            link: "/stocks",
            text: "Total Stock Items Available"
          })
        }
        if (homeViewDetails?.includes(HomeDetails.AUCTION)){
          updatedStats.push({
            title: "Active Auctions",
            count: res?.response?.auctions,
            link: "/auctions",
            text: "Total Auctions Started"
          })
        }
        setStats(updatedStats);
        setState("success");
      } else {
        setState("error");
      }
    } catch (err) {
      console.error(err);
      setState("error");
    }
  }

  useEffect(() => {
    if (!isPublicUser){
      init();
    }
  }, [isPublicUser]);

  if (state === "loading"){
    return (
      <div
        className='flex flex-col items-center justify-center flex-1 w-full'
      >
        <Spin
          size="large"
          tip="Loading..."
        />
      </div>
    )
  }

  if (isPublicUser){
    return (
      <div
        className='flex flex-col items-center justify-center flex-1 w-full'
      >
        <PublicUser />
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center flex-1 w-full container my-4">
      <Row
        gutter={[16, 16]}
        className="w-full"
      >
        {
          stats.map((stat, index) => (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
            >
              <Link
                to={stat.link}
              >
                <div
                  className='p-2 md:p-4 flex flex-row items-center justify-between w-full h-32 bg-white shadow-md rounded-xl hover:shadow-lg transition duration-300 ease-in-out'
                >
                  <div>
                    <Typography.Title
                      level={4}
                      style={{
                        margin: 0
                      }}
                    >
                      {stat.title}
                    </Typography.Title>
                    <Typography.Text
                      style={{
                        margin: 0
                      }}
                    >
                      {stat.text}
                    </Typography.Text>
                  </div>
                  <Typography.Title
                    level={1}
                    className="text-right"
                    style={{
                      margin: 0,
                      fontSize: "3rem",
                      color: "#1890ff"
                    }}
                  >
                    {stat.count}
                  </Typography.Title>
                </div>
              </Link>
            </Col>
          ))
        }
      </Row>
    </div>
  );
}
