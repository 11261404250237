import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/fetch";
import { Col, Row, Table, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useConst } from "../../hooks/const";
import { POViewDetails, getPOViewDetails } from "../../services/access-control";
import { useUser } from "../../hooks/user";

export default function PurchaseOrderDetails() {
  const { id } = useParams();
  const _fetch = useFetch();
  const consts = useConst();
  const user = useUser();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [po, setPO] = useState({});
  const poItemDetails = getPOViewDetails(user, consts);

  let poItemCols = [];
  if (poItemDetails?.includes(POViewDetails.NAME)) {
    poItemCols.push({
      title: "Item",
      dataIndex: "name",
      key: "item",
    });
  }
  if (poItemDetails?.includes(POViewDetails.QUANTITY)) {
    poItemCols.push({
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) =>
        `${quantity} ${consts?.units?.purchaseOrder?.quantity}`,
    });
  }
  if (poItemDetails?.includes(POViewDetails.PRICE)) {
    poItemCols.push({
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `${price} ${consts?.units?.purchaseOrder?.price}`,
    });
  }

  async function init() {
    try {
      setState("loading");
      const res = await _fetch(`purchase-order/${id}`);
      if (res.success) {
        setPO({
          ...res.response,
          total_price: res.response.items.reduce(
            (acc, item) => acc + item.price,
            0
          ),
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch purchase order details.");
      setState("error");
    }
  }

  useEffect(() => {
    if (id) init();
  }, []);

  return (
    <div className="flex-1 w-full">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Purchase Order Details
        </Typography.Title>
        <Link to={`/purchase-orders?_id=${po._id}`} className="text-lg">
          <EditOutlined className="mr-2" />
            Edit
        </Link>
      </div>
      <div>
        <Typography.Title level={4}>Purchase Order Information</Typography.Title>
        <Row gutter={[16, 16]} className="my-4 md:my-8">
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Purchase Order Date:</b> {moment(po.createdAt).format("DD MMM YYYY")}
            </Typography.Text>
          </Col>
          {
            poItemDetails?.includes(POViewDetails.PRICE) && (
              <Col xs={24} sm={12} md={8}>
                <Typography.Text>
                  <b>Total Price:</b> {po.total_price}{" "}
                  {consts?.units?.purchaseOrder?.price}
                </Typography.Text>
              </Col>
            )
          }
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Purchase Order Updated:</b>{" "}
              {moment(po.updatedAt).format("DD MMM YYYY")}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Purchase Order Fulfilled:</b>{' '}
              {
                po.fulfilled ? 'Yes' : 'No'
              }
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Purchase Order Paid: </b>
              {
                po.paid ? 'Yes' : 'No'
              }
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Estimated Date:</b>{" "}
              {moment(po.delivery_date).format("DD MMM YYYY")}
            </Typography.Text>
          </Col>
          {
            po.fulfilled && po?.fulfilledDate && (
              <Col xs={24} sm={12} md={8}>
                <Typography.Text>
                  <b>Fulfilled Date:</b>{" "}
                  {moment(po.fulfilledDate).format("DD MMM YYYY")}
                </Typography.Text>
              </Col>
            )
          }
          {
            po.paid && po.paidDate && (
              <Col xs={24} sm={12} md={8}>
                <Typography.Text>
                  <b>Paid Date:</b>{" "}
                  {moment(po.paidDate).format("DD MMM YYYY")}
                </Typography.Text>
              </Col>
            )
          }
        </Row>
        <Table
          dataSource={po.items}
          columns={poItemCols}
          loading={state === "loading"}
        />
      </div>
      <div>
        <Typography.Title level={4}>Vendor Information</Typography.Title>
        <Row gutter={[16, 16]} className="my-4 md:my-8">
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Client Name:</b>{" "}
              <Link
                to={`/vendors?_id=${po.vendor?._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {po.vendor?.name}
              </Link>
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor GST Number:</b> {po.vendor?.gst_number}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor GST Address:</b> {po.vendor?.gst_address}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Payment Terms:</b> {po?.payment_terms} days
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Phone Numbers:</b>{" "}
              {po.vendor?.phones?.map((phone, index) => (
                <Link key={index} to={`tel:${phone}`}>
                  {phone}{" "}
                </Link>
              ))}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Emails:</b>{" "}
              {po.vendor?.emails?.map((email, index) => (
                <Link key={index} to={`mailto:${email}`}>
                  {email}{" "}
                </Link>
              ))}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Account Number:</b> {po.vendor?.bank_details?.account_number}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank IFSC Code:</b> {po.vendor?.bank_details?.ifsc_code}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Name:</b> {po.vendor?.bank_details?.bank_name}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Branch:</b> {po.vendor?.bank_details?.branch_name}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Account Holder Name:</b> {po.vendor?.bank_details?.account_holder_name}
            </Typography.Text>
          </Col>
        </Row>
      </div>
      <div>
        <Typography.Title level={4}>Vendor Bank Information</Typography.Title>
        <Row gutter={[16, 16]} className="my-4 md:my-8">
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Account Number:</b> {po.vendor?.bank_details?.account_number}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank IFSC Code:</b> {po.vendor?.bank_details?.ifsc_code}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Name:</b> {po.vendor?.bank_details?.bank_name}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Branch:</b> {po.vendor?.bank_details?.branch_name}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Vendor Bank Account Holder Name:</b> {po.vendor?.bank_details?.account_holder_name}
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </div>
  );
}
