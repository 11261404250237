import { Layout } from "antd"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

import AuctionHeader from "./header";
import AuctionFooter from "./footer";

import { useAuctionUser } from "../../hooks/auctionUser";

const {
    Content
} = Layout

export default function ProtectedAuctionLayout({
    children
}){
    const user = useAuctionUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user || !user?._id) navigate("/login", {
            replace: true
        });
    }, [user, navigate])

    return (
        <Layout
            className="min-h-screen h-auto w-screen flex flex-col items-center justify-between"
        >
            <AuctionHeader />
            <Content
                className="flex flex-col items-center justify-center container grow py-4"
            >
                {children}
            </Content>
            <AuctionFooter />
        </Layout>
    )
}