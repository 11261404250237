import { Button, Form, Input, Typography, message } from "antd";
import { useState } from "react";
import {
    PlusOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/fetch";

export default function CreateEnquiry() {
    const navigate = useNavigate();
    const _fetch = useFetch();

    const [state, setState] = useState("success"); // ["success", "loading", "error"]

    async function submitEnquiry(val) {
        try {
            setState("loading");
            const res = await _fetch("enquiry", {
                method: "POST",
                body: val,
            });
            if (res.success) {
                message.success("Enquiry submitted successfully, we will contact you soon.");
                setState("success");
                navigate("/");
            } else {
                message.error(res.message);
                setState("error");
            }
        } catch (error) {
            message.error("Something went wrong");
            console.error(error);
            setState("error");
        }
    }

    return (
        <div>
            <Typography.Title level={2} className="text-center font-bold">
                Submit Enquiry
            </Typography.Title>  
            <Typography.Text className="text-center">
                Please fill the form below to submit your enquiry. We will contact you soon. All the updates will be sent to your email.
            </Typography.Text>
            <Form
                name="enquiry"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={submitEnquiry}
                className="md:min-w-[400px]"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input the title!",
                        },
                    ]}
                >
                    <Input
                        placeholder="Enter the title of your enquiry"
                    />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: "Please input the description!",
                        },
                    ]}
                >
                    <Input.TextArea
                        placeholder="Enter the description of your enquiry"
                    />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please input your email!",
                        },
                    ]}
                >
                    <Input
                        placeholder="Enter your email"
                    />
                </Form.Item>
                <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: "Please input your phone number!",
                        },
                    ]}
                >
                    <Input
                        placeholder="Enter your phone number"
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={state === "loading"}
                        className="w-full"
                        icon={<PlusOutlined />}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}