import { useParams } from "react-router-dom";
import useFetch from "../../hooks/fetch";
import {
  PlusOutlined,
  CheckCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useConst } from "../../hooks/const";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Table,
  Typography,
  message,
  Form,
  Select,
  InputNumber,
} from "antd";
import moment from "moment";

export default function AuctionDetails() {
  const { id } = useParams();
  const _fetch = useFetch();
  const consts = useConst();
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]
  const [auction, setAuction] = useState({});
  const [addSystemBidModalVisible, setAddSystemBidModalVisible] =
    useState(false);
  const [addUserToAuctionModal, setAddUserToAuctionModal] = useState({
    visible: false,
    userAdded: false,
  });
  const [vendors, setVendors] = useState([]);

  async function init() {
    try {
      setState("loading");
      const res = await _fetch(`auction/${id}`);
      const res2 = await _fetch(
        `vendor?status=${consts?.vendor_statuses?.APPROVED}`
      );
      if (res.success && res2.success) {
        setAuction(res.response);
        setVendors(res2.response);
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      message.error("Failed to load auction details");
      setState("error");
    }
  }

  async function addUserToAuction(val) {
    try {
      setState("loading");
      const res = await _fetch(`auction/add-user/${id}`, {
        method: "POST",
        body: val,
      });
      if (res.success) {
        message.success("User added to auction successfully");
        setAuction((prev) => {
          return {
            ...prev,
            users: [...prev.users, res.response],
          };
        });
        setAddUserToAuctionModal({
          visible: true,
          userAdded: true,
          id: res.response.id,
          password: res.response.password,
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      message.error("Failed to add user to auction");
      setState("error");
    }
  }

  async function removeUserFromAuction(userId) {
    try {
      setState("loading");
      const res = await _fetch(`auction/remove-user/${id}`, {
        method: "POST",
        body: {
          userId,
        },
      });
      if (res.success) {
        message.success("User removed from auction successfully");
        setAuction((prev) => {
          return {
            ...prev,
            users: prev.users.filter((user) => user.id !== userId),
          };
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      message.error("Failed to remove user from auction");
      setState("error");
    }
  }

  async function addSystemBid(val) {
    try {
      setState("loading");
      const res = await _fetch(`auction/add-bid/${id}`, {
        method: "POST",
        body: val,
      });
      if (res.success) {
        message.success("Bid added to auction successfully");
        setAuction((prev) => {
          return {
            ...prev,
            bids: [...prev.bids, res.response],
          };
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      message.error("Failed to add bid to auction");
      setState("error");
    }
  }

  async function removeSystemBid(bidId) {
    try {
      setState("loading");
      const res = await _fetch(`auction/delete-bid/${bidId}`, {
        method: "DELETE",
      });
      if (res.success) {
        message.success("Bid removed from auction successfully");
        setAuction((prev) => {
          return {
            ...prev,
            bids: prev.bids.filter((bid) => bid._id !== bidId),
          };
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      message.error("Failed to remove bid from auction");
      setState("error");
    }
  }

  async function makeBidWinner(bidId) {
    try {
      setState("loading");
      const res = await _fetch(`auction/mark-winner/${id}`, {
        method: "POST",
        body: {
          bidId,
        },
      });
      if (res.success) {
        message.success("Bid winner made successfully");
        setAuction((prev) => {
          return {
            ...prev,
            winner: res.response,
          };
        });
        setState("success");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (err) {
      message.error("Failed to make bid winner");
      setState("error");
    } finally {
      setState("success");
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="flex-1 w-full">
      <div className="flex justify-between items-center">
        <Typography.Title
          level={2}
          style={{
            margin: 0,
          }}
        >
          Auction Details: {auction.id}
        </Typography.Title>
        {
          moment().isBefore(auction.endDate) && (
            <div className="flex items-center gap-x-4">
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                loading={state === "loading"}
                onClick={() => {
                  setAddUserToAuctionModal({
                    visible: true,
                    userAdded: false,
                  });
                }}
              >
                Add User
              </Button>
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                loading={state === "loading"}
                onClick={() => {
                  setAddSystemBidModalVisible(true);
                }}
              >
                Add System Bid
              </Button>
            </div>
          )
        }
      </div>
      <div>
        <Typography.Title level={4}>Auction Information</Typography.Title>
        <Row gutter={[16, 16]} className="my-4 md:my-8">
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Auction Name:</b> {auction.name}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Auction Description:</b> {auction.description}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Start Date:</b>{" "}
              {moment(auction.startDate).format("DD MMM YYYY hh:mm A")}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>End Date:</b>{" "}
              {moment(auction.endDate).format("DD MMM YYYY hh:mm A")}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Minimum Bid:</b> {auction.minimumBid} {"INR"}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Status:</b> {auction.status}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Item Name:</b> {auction.item?.name}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Item Description:</b> {auction.item?.description}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Typography.Text>
              <b>Item Quantity:</b> {auction.item?.quantity}{" "}
              {auction.item?.unit}
            </Typography.Text>
          </Col>
        </Row>
      </div>
      {auction?.winner && (
        <div>
          <Typography.Title level={4}>
            Auction Winner Information
          </Typography.Title>
          <Row gutter={[16, 16]} className="my-4 md:my-8">
            <Col xs={24} sm={12} md={8}>
              <Typography.Text>
                <b>Winner Vendor Name:</b>{" "}
                {auction.winner?.vendor?.name
                  ? auction.winner?.vendor?.name
                  : "N/A"}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Typography.Text>
                <b>Winner Bid Amount:</b>{" "}
                {auction.winner?.amount ? auction.winner?.amount : "N/A"} {"INR"}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Typography.Text>
                <b>Winnder Vendor GST Number</b>{" "}
                {auction.winner?.vendor?.gst_number
                  ? auction.winner?.vendor?.gst_number
                  : "N/A"}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Typography.Text>
                <b>Winnder Vendor GST Address</b>{" "}
                {auction.winner?.vendor?.gst_address
                  ? auction.winner?.vendor?.gst_address
                  : "N/A"}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Typography.Text>
                <b>Winnder Vendor Type</b>{" "}
                {auction.winner?.vendor?.type
                  ? auction.winner?.vendor?.type
                  : "N/A"}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Typography.Text>
                <b>Winner Vendor Emails:</b>{" "}
                {auction.winner?.vendor?.emails
                  ? auction.winner?.vendor?.emails?.join(", ")
                  : "N/A"}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Typography.Text>
                <b>Winner Vendor Phone Number:</b>{" "}
                {auction.winner?.vendor?.phones
                  ? auction.winner?.vendor?.phones?.join(", ")
                  : "N/A"}
              </Typography.Text>
            </Col>
          </Row>
        </div>
      )}
      <div>
        <Typography.Title level={4}>Auction Bids</Typography.Title>
        <Table
          dataSource={auction.bids}
          rowClassName={(record) => {
            if (record.role === consts?.auction_bid_roles?.SYSTEM) {
              return "bg-blue-100";
            }
            const bidId = record?._id;
            const winnerBidId = auction.winner?._id;
            if (winnerBidId === bidId) {
              return "bg-green-100";
            }
          }}
          columns={[
            {
              title: "User",
              dataIndex: "role",
              key: "role",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              sorter: (a, b) => a.amount - b.amount,
              render: (amount) => `${amount} INR`,
            },
            {
              title: "Vendor Name",
              dataIndex: "vendor",
              key: "vendorName",
              render: (vendor) => (vendor?.name ? vendor.name : "N/A"),
            },
            {
              title: "Vendor GST Number",
              dataIndex: "vendor",
              key: "vendorGST",
              render: (vendor) =>
                vendor?.gst_number ? vendor.gst_number : "N/A",
            },
            {
              title: "Vendor GST Address",
              dataIndex: "vendor",
              key: "vendorGSTAddress",
              render: (vendor) =>
                vendor?.gst_address ? vendor.gst_address : "N/A",
            },
            {
              title: "Vendor Type",
              dataIndex: "vendor",
              key: "vendorType",
              render: (vendor) => (vendor?.type ? vendor.type : "N/A"),
            },
            {
              title: "Actions",
              dataIndex: "_id",
              key: "_id",
              render: (id, record) => (
                <div className="flex gap-x-4">
                  {record.role === consts?.auction_bid_roles?.SYSTEM && (
                    <Button
                      type="primary"
                      danger
                      loading={state === "loading"}
                      onClick={() => removeSystemBid(id)}
                      disabled={moment().isAfter(auction.endDate)}
                    >
                      Delete Bid
                    </Button>
                  )}
                  {moment().isAfter(auction.endDate) &&
                    !auction.winner && record.role !== consts?.auction_bid_roles?.SYSTEM && (
                      <Button
                        type="primary"
                        loading={state === "loading"}
                        onClick={() => makeBidWinner(id)}
                      >
                        Make Winner
                      </Button>
                    )}
                </div>
              ),
            },
          ]}
          className="mt-4 md:mt-8"
          loading={state === "loading"}
        />
      </div>
      <div>
        <Typography.Title level={4}>Auction User Information</Typography.Title>
        <Table
          dataSource={auction.users}
          rowClassName={(record) => {
            const vendorId = record?.vendor?._id;
            const winnerVendorId = auction.winner?.vendor?._id;
            if (winnerVendorId === vendorId) {
              return "bg-green-100";
            }
          }}
          columns={[
            {
              title: "User ID",
              dataIndex: "id",
              key: "id",
            },
            {
              title: "Vendor Name",
              dataIndex: "vendor",
              key: "vendorName",
              render: (vendor) => vendor?.name,
            },
            {
              title: "Vendor GST Number",
              dataIndex: "vendor",
              key: "vendorGST",
              render: (vendor) => vendor?.gst_number,
            },
            {
              title: "Vendor GST Address",
              dataIndex: "vendor",
              key: "vendorGSTAddress",
              render: (vendor) => vendor?.gst_address,
            },
            {
              title: "Vendor Type",
              dataIndex: "vendor",
              key: "vendorType",
              render: (vendor) => vendor?.type,
            },
            {
              title: "Actions",
              dataIndex: "id",
              key: "id",
              render: (id) => (
                <Button
                  type="primary"
                  danger
                  onClick={() => removeUserFromAuction(id)}
                  loading={state === "loading"}
                  disabled={moment().isAfter(auction.endDate)}
                >
                  Remove User
                </Button>
              ),
            },
          ]}
          className="mt-4 md:mt-8"
          loading={state === "loading"}
        />
      </div>
      <Modal
        title="Add User to Auction"
        open={addUserToAuctionModal.visible}
        onCancel={() => {
          setAddUserToAuctionModal({
            visible: false,
            userAdded: false,
          });
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          name="addUserToAuction"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={addUserToAuction}
          className="md:min-w-[400px]"
        >
          <Form.Item
            label="Select Vendor"
            name={["user", "vendor"]}
            rules={[
              {
                required: true,
                message: "Please select a vendor",
              },
            ]}
          >
            <Select
              placeholder="Select a vendor"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={addUserToAuctionModal.userAdded}
            >
              {vendors
                ?.filter(
                  (vendor) =>
                    !auction?.users?.find(
                      (user) =>
                        user?.vendor?._id?.toString() === vendor._id.toString()
                    )
                )
                .map((vendor) => (
                  <Select.Option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {addUserToAuctionModal.userAdded ? (
            <>
              <Typography.Text className="block mb-2">
                <b>User's ID:</b> {addUserToAuctionModal.id}
              </Typography.Text>
              <Typography.Text className="block mb-2">
                <b>User's Password:</b> {addUserToAuctionModal.password}
              </Typography.Text>
              <Button
                type="primary"
                className="mb-2 w-full"
                onClick={() => {
                  navigator.clipboard.writeText(addUserToAuctionModal.password);
                  message.success("User ID copied to clipboard");
                }}
                icon={<CopyOutlined />}
              >
                Copy Password
              </Button>
              <Form.Item>
                <Button
                  type="primary"
                  loading={state === "loading"}
                  className="w-full"
                  icon={<CheckCircleOutlined />}
                  onClick={() => {
                    setAddUserToAuctionModal({
                      visible: false,
                      userAdded: false,
                    });
                  }}
                >
                  Done
                </Button>
              </Form.Item>
            </>
          ) : (
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={state === "loading"}
                disabled={addUserToAuctionModal.userAdded}
                className="w-full"
                icon={<PlusOutlined />}
              >
                Add User
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        title="Add System Bid"
        open={addSystemBidModalVisible}
        onCancel={() => {
          setAddSystemBidModalVisible(false);
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          name="addSystemBid"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={addSystemBid}
          className="md:min-w-[400px]"
        >
          <Form.Item
            label="Add amount"
            name={["bid", "amount"]}
            rules={[
              {
                required: true,
                message: "Please enter an amount",
              },
            ]}
          >
            <InputNumber
              placeholder="Enter amount"
              className="w-full"
              min={auction.minimumBid}
              suffix="INR"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state === "loading"}
              className="w-full"
              icon={<PlusOutlined />}
            >
              Add Bid
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
