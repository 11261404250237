import { ConfigProvider, App as AntdApp } from 'antd';
import { Provider } from 'react-redux';
import InternalIndex from "./InternalIndex";
import AuctionIndex from "./Auction";
import store from './redux/store';
import auctionStore from './Auction/redux/auctionStore';



function App() {
  const isAuctionSubdomain = window.location.hostname.includes("auction");

  return (
    <Provider
      store={
        isAuctionSubdomain ? auctionStore : store
      }
    >
      <ConfigProvider
        theme={{
          token: {
            fontFamily: '"Outfit", sans-serif'
          }
        }}
      >
        <AntdApp>
          {
            isAuctionSubdomain ? <AuctionIndex /> : <InternalIndex />
          }
        </AntdApp>
      </ConfigProvider>
    </Provider>
  )
}

export default App;
