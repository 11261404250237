import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    email: "",
    name: "",
};

const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        login: (state, action) => {
            return action.payload;
        },
        logout: (state) => {
            return initialUserState;
        },
        update: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        }
    }
});

export const {
    login,
    logout,
    update,
} = userSlice.actions;

export default userSlice.reducer;