import AuctionDetails from '../pages/Auction';
import AuctionLogin from '../pages/Login';
import NotFound from '../pages/NotFound';

import AuctionMainLayout from '../components/layout/main';
import ProtectedAuctionLayout from '../components/layout/protected';

const components = {
    home: {
        component: <ProtectedAuctionLayout children={<AuctionDetails />} />,
        path: '/',
        title: 'Home',
    },
    login: {
        component: <AuctionMainLayout children={<AuctionLogin />} />,
        path: '/login',
        title: 'Login',
    },
    notFound: {
        component: <AuctionMainLayout children={<NotFound />} />,
        path: '*',
        title: 'Not Found',
    },
    
}

const publicRoutes = [
    components.home,
    components.login,
    components.notFound,
]

export function getAuctionRoutes(user = {}){
    if (!user || !user?._id) return publicRoutes;
    else {
        return [
            components.home,
            components.notFound,
        ]
    }
}

export function getAuctionMenuRoutes(user = {}){
    if (!user || !user?._id) {
        return [
            components.home,
            components.login,
        ]
    }
    else {
        return [
            components.home
        ]
    }
}

