import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

// Service
import useFetch from "./hooks/fetch";
import { login as loginAction } from "./redux/userSlice";
import { getRoutes } from "./services/access-control";
import { setConst } from "./redux/constSlice";
import { useUser } from "./hooks/user";
import { useConst } from "./hooks/const";

export default function InternalIndex(){
  const user = useUser();
  const consts = useConst();
  const _fetch = useFetch();
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState(getRoutes(user, consts));
  const [state, setState] = useState("loading"); // ["success", "loading", "error"]

  useEffect(() => {
    setRoutes(getRoutes(user, consts));
  }, [user, consts]);

  useEffect(() => {
    // Let it execute first before passing routes to createBrowserRouter so that the updated routes will be used
    async function init() {
      try {
        let res = await _fetch("user", {}, false);
        if (res.success) {
          dispatch(loginAction(res.response));
          res = await _fetch("const", {}, false);
          if (res.success) {
            dispatch(setConst(res.response))
          }
        } else setState("error");
      } catch (err) {
        console.error(err);
        setState("error");
      } finally {
        setState("success");
      }
    }
    init();
  }, []);

  if (state === "loading") {
    return (
      <div
        className="h-screen w-screen flex justify-center items-center"
      >
        <Spin size="large" />
      </div>
    )
  }
  return (
    <RouterProvider router={createBrowserRouter(routes?.map((route) => ({
      element: route.component,
      path: route.path
    })))} />
  )
}