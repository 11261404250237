import { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import useAuctionFetch from "../hooks/fetch";
import {
    auctionUserLogin
} from "../redux/auctionUserSlice";

export default function AuctionLogin(){
    const _fetch = useAuctionFetch();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [state, setState] = useState("success"); // ["success", "loading", "error"]

    async function handleUserLogin(val) {
        try {
            setState("loading");
            let res = await _fetch("user/login", {
                method: "POST",
                body: val,
            });
            if (res.success) {
                message.success("Login successful")
                dispatch(auctionUserLogin(res.response));
                setState("success");
                navigate("/");
            } else {
                message.error(res.message);
                setState("error");
            }
        } catch (err) {
            console.error(err);
            message.error("Something went wrong");
            setState("error");
        }
    }

    return (
        <div>
        <h1 className="text-center text-2xl font-bold mb-8">Auction Login</h1>
        <Form
            name="login"
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={handleUserLogin}
            autoComplete="off"
            className="md:min-w-[400px]"
        >
            <Form.Item
                label="Login ID"
                name="id"
                rules={[
                    {
                        required: true,
                        message: "Please input your login id!",
                    },
                ]}
            >
                <Input placeholder="Login ID" />
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                    required: true,
                    message: "Please input your password!",
                    },
                ]}
            >
                <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
            <Button
                    type="primary"
                    htmlType="submit"
                    loading={state === "loading"}
                    className="w-full"
                >
                    Login
                </Button>
            </Form.Item>
        </Form>
        </div>
    )
}