import { Layout } from 'antd';
const { Footer: AntdFooter } = Layout;
export default function Footer(){
    return (
        <AntdFooter
            className="w-full flex items-center justify-center p-0"
        >
            <div
                className='container w-full flex-col items-center justify-center p-0'
            >
                <p
                    className='text-center'
                >
                    Rashi Cables ©{2004}    
                </p>
                <p className='text-center'>
                    Made with ❤️ by <a target='_blank' href="https://www.linkedin.com/in/dakkkshh/" rel="noreferrer">dakkkshh</a>
                </p>
            </div>
        </AntdFooter>
    )
}