import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    id: "",
    vendor: {}
};

const auctionUserSlice = createSlice({
    name: "auctionUser",
    initialState: initialUserState,
    reducers: {
        login: (state, action) => {
            return action.payload;
        },
        logout: (state) => {
            return initialUserState;
        },
        update: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        }
    }
});

export const {
    login: auctionUserLogin,
    logout: auctionUserLogout,
    update: auctionUserUpdate,
} = auctionUserSlice.actions;

export default auctionUserSlice.reducer;