import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

// Service
import useAuctionFetch from "./Auction/hooks/fetch";
import {
    auctionUserLogin
} from "./Auction/redux/auctionUserSlice";
import { getAuctionRoutes } from "./Auction/services/access-control";
import { useAuctionUser } from "./Auction/hooks/auctionUser";

export default function AuctionIndex(){
    const user = useAuctionUser();
    const _fetch = useAuctionFetch();
    const dispatch = useDispatch();
    const [routes, setRoutes] = useState(getAuctionRoutes(user));
    const [state, setState] = useState("loading"); // ["success", "loading", "error"]

    useEffect(() => {
        setRoutes(getAuctionRoutes(user));
    }, [user]);

    useEffect(() => {
        // Let it execute first before passing routes to createBrowserRouter so that the updated routes will be used
        async function init() {
            try {
                let res = await _fetch("user", {}, false);
                if (res.success) {
                    dispatch(auctionUserLogin(res.response));
                } else setState("error");
            } catch (err) {
                console.error(err);
                setState("error");
            } finally {
                setState("success");
            }
        }
        init();
    }, []);

    if (state === "loading") {
        return (
          <div
            className="h-screen w-screen flex justify-center items-center"
          >
            <Spin size="large" />
          </div>
        )
      }

    return (
        <RouterProvider router={createBrowserRouter(routes?.map((route) => ({
            element: route.component,
            path: route.path
        })))} />
    )
}