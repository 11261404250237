import { Typography } from "antd";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="flex flex-col flex-1 w-full">
      <div className="flex-1 w-full flex flex-col justify-center items-center min-h-[75vh]">
        <Typography.Title
            className="text-center"
            style={{
                fontSize: "2rem",
                fontWeight: "bold"
            }}
        >
            404 - Page Not Found
        </Typography.Title>
        <Typography.Text>
            Looks like you're lost. The page you're looking for was not found.
        </Typography.Text>
        <Link to="/">
            Go back to the homepage
        </Link>
      </div>
    </div>
  );
}
