import { useEffect, useState } from "react";
import { Button, Form, Input, App } from "antd";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import useFetch from "../../../hooks/fetch";
import { login as loginAction } from "../../../redux/userSlice";
import { useUser } from "../../../hooks/user";
import { setConst } from "../../../redux/constSlice";

export default function Login() {
  const {
    _id
  } = useUser();
  const _fetch = useFetch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    message
  } = App.useApp();

  const [state, setState] = useState("success"); // ["success", "loading", "error"]

  async function submitForm(val) {
    try {
      setState("loading");
      let res = await _fetch("user/login", {
        method: "POST",
        body: val,
      });
      if (res.success) {
        message.success(res.message);
        dispatch(loginAction(res.response));
        res = await _fetch("const", {}, false);
        if (res.success) {
          dispatch(setConst(res.response))
        }
        setState("success");
        navigate("/");
      } else {
        message.error(res.message);
        setState("error");
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong");
      setState("error");
    }
  }

  useEffect(() => {
    if (_id) navigate("/", {
      replace: true
    });
  }, [_id, navigate]);

  return (
    <div>
      <h1 className="text-center text-2xl font-bold mb-8">Login</h1>
      <Form
        name="login"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitForm}
        autoComplete="off"
        className="md:min-w-[400px]"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={state === "loading"}
            className="w-full"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
