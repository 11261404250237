import { Col, Input, Row, Steps, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Typewriter from "typewriter-effect/dist/core";
import moment from "moment";
import Lottie from 'react-lottie';
import useFetch from "../../hooks/fetch";
import * as animationData from '../../assets/lottie/track-order.json';

export default function TrackOrder() {
  const _fetch = useFetch();
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get("order_id") || "";
  const orderTrackingSteps = [
    "Got your order workflow number?",
    "You can track your order by entering your order workflow number in the field below.",
    "Once you enter your order workflow number, you will be able to see the order status.",
  ];

  const [order, setOrder] = useState({});

  async function fetchOrder(workflowNumber) {
    try {
      setLoading(true);
      const res = await _fetch(`order/worflow-number/${workflowNumber}`);
      if (res.success) {
        setOrder(res.response);
        document.getElementById("track").scrollIntoView({ behavior: "smooth" });
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while fetching the order details.");
    } finally {
      setLoading(false);
    }
  }

  const [loading, setLoading] = useState(false);

  function handleSearch(value) {
    fetchOrder(value);
  }

  useEffect(() => {
    new Typewriter("#typewriter", {
      strings: orderTrackingSteps,
      autoStart: true,
      loop: true,
      cursor: "|",
      delay: 50,
    });
    if (order_id) {
      fetchOrder(order_id);
    }
  }, []);

  return (
    <div className="flex flex-col flex-1 w-full">
      <div className="flex-1 w-full flex flex-col justify-center items-center min-h-[75vh]">
        <Typography.Title id="typewriter" className="text-center" />
        <Input.Search
          placeholder="Enter your order number"
          enterButton="Track Order"
          size="large"
          onSearch={handleSearch}
          className="mt-4 w-80 md:w-96 lg:w-1/2 xl:w-1/3"
          loading={loading}
          defaultValue={order_id}
        />
      </div>
      <div id="track" className="mt-8 mx-4 md:mx-0 min-h-[75vh]">
        {order?._id && (
          <div className="flex bg-[#a2c8ff] rounded-md lg:rounded-lg xl:rounded-xl p-4 md:p-8 lg:p-12">
            <Row gutter={[16, 16]} className="flex-1" align={"stretch"}>
              <Col xs={24} md={12}>
                <div className="h-full flex justify-center items-center">
                  {
                    order?.location_history?.length > 0 ? (
                      <Steps
                        direction="vertical"
                        current={order?.location_history?.length || 0}
                        items={order?.location_history?.map((location) => {
                          return {
                            title: (
                              <Typography.Title
                                level={4}
                                style={{
                                  margin: "0 0 4px 0",
                                }}
                              >
                                {location.location}
                              </Typography.Title>
                            ),
                            description: (
                              <Typography.Text className="text-center">
                                {moment(location.timestamp).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                              </Typography.Text>
                            ),
                          };
                        })}
                      />
                    ) : (
                      <div
                        className="flex flex-col justify-center items-center px-4 flex-wrap"
                      >
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData.default,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                          height={375}
                          width={375}
                        />
                        <div
                          style={{
                            width: "90%"
                          }}
                        >
                          <Typography.Title
                            level={3}
                            className="text-center w-full"
                          >
                            No location history available right now.
                          </Typography.Title>
                        </div>
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="h-full flex flex-col gap-y-4">
                  <Typography.Title
                    level={2}
                    style={{
                      margin: "0 0 4px 0",
                    }}
                  >
                    {`Here are your order details as promised ✨, ${order?.client?.name}`}
                  </Typography.Title>
                  <Typography.Text>
                    {`Your order was created on `}
                    <strong>
                      {moment(order?.createdAt).format(
                        "Do MMMM YYYY, h:mm:ss a"
                      )}
                    </strong>
                    .
                  </Typography.Text>
                  <Typography.Text>
                    {`Your order is currently in process and is expected to be delivered by `}
                    <strong>
                      {moment(order?.estimated_time).format("Do MMMM YYYY")}
                    </strong>
                    .
                  </Typography.Text>
                  <Typography.Text>
                    {`Consignee address for your order is `}
                    <strong>{order?.consignee_address}</strong>
                  </Typography.Text>
                  <Typography.Text>
                    The estimated delivery date is subject to change based on
                    the current location and the delivery partner's
                    availability.
                  </Typography.Text>
                  <Typography.Text>
                    {`Current status of your order is `}
                    <strong>{order?.status}</strong>.
                  </Typography.Text>
                  <Typography.Text>
                    {`Your order's workflow number is `}
                    <strong>{order?.workflow_number}</strong>.
                  </Typography.Text>
                  <Typography.Title level={4}>Items in your order:</Typography.Title>
                  <ul>
                    {
                      order?.items?.map((item) => (
                        <li key={item._id}>
                          <Typography.Text
                            style={{
                              margin: 0,
                            }}
                            className="block"
                          >
                            {`Item Name: ${item.name}`}
                          </Typography.Text>
                          <strong
                            className="block"
                          >
                            {`Quantity: ${item.quantity} m`}
                          </strong>
                          <strong
                            className="block"
                          >
                            {`Price: ${item.price} INR`}
                          </strong>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}
