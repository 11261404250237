import { Layout } from "antd"

import Header from "./header"
import Footer from "./footer"

const {
    Content
} = Layout
export default function MainLayout({
    children,
    useContainer = true,
    usePadding = true,
}){
    return (
        <Layout
            className="min-h-screen h-auto w-screen flex flex-col items-center justify-between"
        >
            <Header />
            <Content
                className={`flex flex-col items-center justify-center grow ${useContainer ? 'container' : 'w-full'} ${usePadding ? 'py-4' : ''}`}
            >
                {children}
            </Content>
            <Footer />
        </Layout>
    )
}